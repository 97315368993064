/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import BlockDefault from 'components/elements/BlockDefault'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, content, yoast_meta: yoast },
  },
}) => (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
      />
      
      <div className="container py-5 mt-5">
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <BlockDefault>
              <h1 className="color-contrast">{title}</h1>
              <ParseContent className="mt-4" content={content} />
            </BlockDefault>
          </div>
        </div>
      </div>
    </Layout>
  )

export default PageTemplate
